import RhombusButton from "components/common/RhombusButton";
import {
  CTA_BUTTON_COPY_3,
  CTA_LINK_4,
} from "components/data/landing-page-data";
import {
  FlexCenter,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import { styled } from "linaria/react";
import React from "react";

const HeroContainer = styled(SectionContainer)`
  background: var(--gradient-light-bottom);
  padding-bottom: 0;
`;

const HeroInner = styled(SectionInner)`
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const HeroLeft = styled(SectionCol)`
  padding-bottom: 5rem;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
    padding-bottom: 0;
    text-align: center;
    .text {
      align-items: center;
    }
  }
`;

const HeroRight = styled(FlexCenter)`
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const BadgeRow = styled.div`
  display: flex;
  gap: 1.25rem;
`;

function Hero() {
  const badgeProps = {
    height: 92,
    width: 70,
    quality: 100,
    objectFit: "contain",
    loading: "eager",
    placeholder: "blurred",
  };

  return (
    <HeroContainer>
      <HeroInner>
        <HeroLeft>
          <TextContainer style={{ gap: "2rem" }} className="text" width="665px">
            <FancyPageTitle style={{ fontWeight: 800 }}>
              Future proof your security with flexible, cloud-driven protection
            </FancyPageTitle>
            <MainParagraph>
              Outdated security systems leave your organization ill-equipped for
              growth and vulnerable to evolving threats. See how Rhombus is
              changing enterprise security with a flexible, cloud-driven
              platform that delivers scalable, cutting-edge protection.
            </MainParagraph>
            <RhombusButton
              title={CTA_BUTTON_COPY_3}
              useTitleCase={false}
              path={CTA_LINK_4}
              style={{ padding: "1.25rem" }}
            />
            <BadgeRow>
              <StaticImage
                src="../../common/img/g2-badges/badge-6.png"
                alt="G2 - Most Implementable - Summer 2024"
                {...badgeProps}
              />
              <StaticImage
                src="../../common/img/g2-badges/badge-4.png"
                alt="G2 - Mid-Market Leader - Summer 2024"
                {...badgeProps}
              />
              <StaticImage
                src="../../common/img/g2-badges/badge-5.png"
                alt="G2 - Best Usablility - Summer 2024"
                {...badgeProps}
              />
              <StaticImage
                src="../../common/img/g2-badges/badge-7.png"
                alt="G2 - Momentum Leader - Summer 2024"
                {...badgeProps}
              />
            </BadgeRow>
          </TextContainer>
        </HeroLeft>
        <HeroRight>
          <StaticImage
            src="./assets/mixed-products.png"
            objectFit="contain"
            quality={100}
            loading="eager"
            objectPosition="bottom "
            placeholder="blurred"
            style={{ maxWidth: "600px" }}
          />
        </HeroRight>
      </HeroInner>
    </HeroContainer>
  );
}

export default Hero;
