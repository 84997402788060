import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { styled } from "linaria/react";
import React from "react";

const LogoRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 5rem;
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const LogoWrapper = styled.div`
  opacity: 0.5;
`;

export default function LogoBlade({ style }) {
  const renderContent = data => {
    const logos = [
      data.logo1,
      data.logo2,
      data.logo3,
      data.logo4,
      data.logo5,
      data.logo6,
      data.logo7,
    ];
    return (
      <SectionContainer style={style}>
        <SectionInner>
          <LogoRow>
            {logos.map(logo => (
              <LogoWrapper key={logo}>
                <GatsbyImage
                  image={getImage(logo)}
                  alt=""
                  objectFit="contain"
                  loading="eager"
                />
              </LogoWrapper>
            ))}
          </LogoRow>
        </SectionInner>
      </SectionContainer>
    );
  };

  const GET_IMAGES = graphql`
    query {
      logo1: file(
        relativePath: { eq: "components/common/logos/slider/navy/amazon.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            height: 30
            width: 100
            quality: 100
          )
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/slider/navy/aramark.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            height: 36
            width: 126
            quality: 100
          )
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/slider/navy/hurley.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            height: 56
            width: 98
            quality: 100
          )
        }
      }
      logo4: file(
        relativePath: {
          eq: "components/common/logos/slider/navy/ready-spaces.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            height: 28
            width: 105
            quality: 100
          )
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/slider/navy/stryker.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            height: 25
            width: 89
            quality: 100
          )
        }
      }
      logo6: file(
        relativePath: { eq: "components/common/logos/slider/navy/goodwill.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            height: 17
            width: 105
            quality: 100
          )
        }
      }
      logo7: file(
        relativePath: { eq: "components/common/logos/slider/navy/clark.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            height: 30
            width: 101
            quality: 100
          )
        }
      }
    }
  `;

  return <StaticQuery render={renderContent} query={GET_IMAGES} />;
}
